/* CSS */
import "@assets/scss/all.scss";

/* JS */
import "@assets/js/lib/limet/jquery.cookies";
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'lazysizes';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons); // loads the Icon plugin

// Init jquery cookies
$('#cookie-consent').cookies();

//Add Class to headings, which have strong-childs to style the unbolded parts in thin, if they are basically light f.e.
$('h1, h2, h3, h4, h5, h6').each(function() {
    if ($(this).find('strong').length > 0) {
        $(this).addClass('has-strong');
    }
});

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}